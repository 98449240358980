export class Usuarios{
    public Id : string;
    public NIF : string;
    public Nombre : string;
    public Apellidos : string;
    public Movil : number;

    public Email : string;
    public ConfEmail : string;
    public Password : string;
    public ConfPass : string;

    public legalTerminos : boolean;
    public legalCondiciones: boolean;

    //Tarjeta Lazo
    public TLazo : number;
    public TLazoAlias : string;
    public descripcion : string;

    //Tarjeta Credito
    public Titular : string;
    public TCredito : number;
    public Fecha : Date;
    public CVV : number;

    constructor(){
        
        this.TLazo = null;
    }
    
}