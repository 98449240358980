import { Component, OnInit } from '@angular/core';
import { Usuarios } from 'src/app/models/usuarios';
import { ConsultaService } from '../../services/consulta.service';

@Component({
  selector: 'app-tarjeta-credito',
  templateUrl: './tarjeta-credito.page.html',
  styleUrls: ['./tarjeta-credito.page.scss'],
})
export class TarjetaCreditoPage implements OnInit {
  

  public usuario : Usuarios;
  public year : number;
  
  constructor(
    _consulta : ConsultaService
    ) {
      this.usuario = new Usuarios();
    }
    
    ngOnInit() {
      this.maxDate();
    }
    
    
    sendCreditCard(){
      console.log(this.usuario);
      this.usuario = new Usuarios();
    }
    
    maxDate(){
    let today = new Date();
    this.year = today.getFullYear();
    this.year = this.year + 10;

  }
}
