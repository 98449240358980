import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Md5 } from 'ts-md5';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.page.html',
  styleUrls: ['./change-pass.page.scss'],
})
export class ChangePassPage implements OnInit {

  public pass : string;
  public pass2 : string;
  public acceso : number;
  public exito : boolean;



  constructor(
    private _consulta : ConsultaService,
    public alertController: AlertController,
    private _router : Router

  ) {
    this.exito = false;
    
   }

  ngOnInit() {

  }

  async presentAlert(head, str) {
    const alert = await this.alertController.create({
      header: head,
      message: str,
      buttons: ['OK']
    });
    await alert.present();
  }

  submit(){
    this.compruebaPass();
  }

  compruebaPass(){
    if(this.pass == undefined || this.pass == "" || this.pass2 == undefined || this.pass2 == ""){
      this.presentAlert("Atencion!","Faltan campos por rellenar");
    }
    else if(this.pass === this.pass2){
      this.sendPass();
    }else{
      this.presentAlert("Atencion!","Las contraseñas no son iguales");
    }
  }

  sendPass(){
    let myCode = Md5.hashStr(this.pass);
    
    let x = '{"clave" : "PASS", "id" : "' + this._consulta.usuario.Id  + '", "code" : "' + myCode + '"}';

    this._consulta.sendPass(x).subscribe(
      result => {
        this.acceso = result['acceso'];
        if(this.acceso == 1){
          this.exito = true;
          this.presentAlert("Genial!","Tu contraseña ha sido actualiza");
          this._router.navigate(['/mi-cuenta']);
          
        }else{
          this.exito = false;
          this.presentAlert("Atencion!","No se ha podido actualizar tu contraseña");
        }

      },
      error => {
         console.log(<any>error);
        this.presentAlert("Atencion!","Ha ocurrido un error");
      }
    )
  }

  

}
