export class Reservas {


    public id : string;
    public identificadorapertura : string;
    public estado : string;
    public descripcion : string;
    public fechamodificacion : string;
    public tiempoRestante : string ;

    constructor(){
        this.tiempoRestante = "";
    }

}