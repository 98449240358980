import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'principal', pathMatch: 'full' },
  { path: 'principal', loadChildren: './pages/principal/principal.module#PrincipalPageModule' },
  { path: 'aparcar', loadChildren: './pages/aparcar/aparcar.module#AparcarPageModule' },
  { path: 'acceso', loadChildren: './pages/acceso/acceso.module#AccesoPageModule' },
  { path: 'ayuda', loadChildren: './pages/ayuda/ayuda.module#AyudaPageModule' },
  { path: 'acceder', loadChildren: './pages/acceder/acceder.module#AccederPageModule' },
  { path: 'registrarse', loadChildren: './pages/registrarse/registrarse.module#RegistrarsePageModule' },
  { path: 'retirar', loadChildren: './pages/retirar/retirar.module#RetirarPageModule' },
  { path: 'mi-cuenta', loadChildren: './pages/mi-cuenta/mi-cuenta.module#MiCuentaPageModule' },
  { path: 'mis-bicis', loadChildren: './pages/mis-bicis/mis-bicis.module#MisBicisPageModule' },
  { path: 'metodos-pago', loadChildren: './pages/metodos-pago/metodos-pago.module#MetodosPagoPageModule' },
  { path: 'tarjeta-credito', loadChildren: './pages/tarjeta-credito/tarjeta-credito.module#TarjetaCreditoPageModule' },
  { path: 'tarjeta-lazo', loadChildren: './pages/tarjeta-lazo/tarjeta-lazo.module#TarjetaLazoPageModule' },
  { path: 'change-pass', loadChildren: './pages/modals/change-pass/change-pass.module#ChangePassPageModule' },
  { path: 'tarifas', loadChildren: './pages/tarifas/tarifas.module#TarifasPageModule' },
  { path: 'mis-reservas', loadChildren: './pages/mis-reservas/mis-reservas.module#MisReservasPageModule' },
  { path: 'elegir-tarjeta-lazo', loadChildren: './pages/elegir-tarjeta-lazo/elegir-tarjeta-lazo.module#ElegirTarjetaLazoPageModule' },
  { path: 'elegir-codigo', loadChildren: './pages/elegir-codigo/elegir-codigo.module#ElegirCodigoPageModule' },
  { path: 'tarjeta-lazo-detail/:id/:edit', loadChildren: './pages/tarjeta-lazo-detail/tarjeta-lazo-detail.module#TarjetaLazoDetailPageModule' },
  { path: 'aviso-legal', loadChildren: './pages/aviso-legal/aviso-legal.module#AvisoLegalPageModule' },
  { path: 'contacto', loadChildren: './pages/contacto/contacto.module#ContactoPageModule' },
  { path: 'forgot-password', loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: '**', redirectTo: 'principal', pathMatch: 'full' },


]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
