import {Component, OnInit} from '@angular/core';

import {Platform, ActionSheetController, NavController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {MenuController} from '@ionic/angular';
import {ConsultaService} from './services/consulta.service';
import {Router} from '@angular/router';
import {Data} from './services/config';

// import { PrincipalPage } from './pages/index.pages';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {


    // rootPage:any = PrincipalPage;
    public _email: string;

    constructor(
        public platform: Platform,
        private splashScreen: SplashScreen,
        private actionSheetCtrl: ActionSheetController,
        private statusBar: StatusBar,
        private menu: MenuController,
        public _consulta: ConsultaService,
        private _router: Router,
        private nav: NavController,
    ) {
        this._email = Data.email;
        this.initializeApp();

        // this.backButtonEvent();
        this.backButtonMenuEvent();
    }

    ngOnInit() {
        //this._router.navigate(['']);

        if (this.platform.is('android')) {
            this.statusBar.backgroundColorByHexString('#fff');
        }

    }

    // ionViewWillLeave(){
    //   if(!this.menu.isOpen()){
    //     this.backButtonEvent();
    //   }else{
    //     this.backButtonMenuEvent();

    //   }
    // }

    backButtonEvent() {
        this.platform.backButton.subscribe(async () => {
            try {
                const element = await this.actionSheetCtrl.getTop();
                if (element) {
                    element.dismiss();
                    return;
                }
            } catch (error) {
            }
        });
    }

    backButtonMenuEvent() {

        if (this.menu.isOpen()) {
            this.platform.backButton.subscribeWithPriority(9999, () => {
                // this.menu.close();
                try {
                    const element = this.menu.getOpen();
                    if (element !== null) {
                        this.menu.close();
                        // return true;
                    }

                } catch (e) {

                }
            });
        } else {
            this.nav.back();
        }

    }

    cierraMenu() {
        this.menu.close();
    }

    openFirst() {
        this.menu.enable(true, 'first');
        this.menu.open('first');
    }

    openEnd() {
        this.menu.open('end');
    }

    openCustom() {
        this.menu.enable(true, 'custom');
        this.menu.open('custom');
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    cerrarSesion() {
        this._consulta.cerrarSesion();
        this._router.navigate(['/acceder']);
        this.cierraMenu();
    }

    avisoLegal() {
        this._router.navigate(['/registrarse']);
        this.cierraMenu();
    }


}
