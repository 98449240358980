import { Component, OnInit } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Usuarios } from 'src/app/models/usuarios';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.page.html',
  styleUrls: ['./registrarse.page.scss'],
  providers: [Camera]
})
export class RegistrarsePage implements OnInit {

  public step2: boolean;
  public marcas: Array<string>;
  public usuario: Usuarios;
  public confirmarEmail: string;
  public confirmarPassword: string;

  public bntSiguiente: boolean;
  public CTLazo: string;

  constructor(
    public _camera: Camera,
    private _router: Router,
    private _consulta: ConsultaService,
    public alertController: AlertController
  ) {
    this.step2 = false;   //CAMBIAR!!!
    this.marcas = ["WTB", "X-Bionic", "X-Socks", "Xenofit", "XLC"];
    this.usuario = new Usuarios();
    this.usuario.legalCondiciones = false;
    // this.usuario.legalTerminos = false;
  }

  ngOnInit() {
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Faltan campos por rellenar.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertTerminosYCondiciones() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Para poder continuar debe de aceptar los Términos y Condiciones.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertTLazo() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'El número de la Tarjeta Lazo no es correcto',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertEmail() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Los emails no coinciden',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertPass() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Las contraseñas no coinciden',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertEmailNoValido() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'El Email no es válido',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertExitoRegistro() {
    const alert = await this.alertController.create({
      header: '¡Genial!',
      message: 'Te has registrado con éxito. Ya puedes usar Bizia.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorRegistro() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Algo ha ido mal... Inténtalo más tarde.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorRegistroTarjetaLazo() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'No hemos podido registrar tu tarjeta. Inténtalo desde la sección Mis Tarjetas',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorNombre() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Escribe un nombre por favor',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorApellidos() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Por favor, escribe tus apellidos.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorNIF() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'El DNI no es válido',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorMovil() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Introduce un número de movil válido.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorComparativaTLazo(){
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Debes introducir el numero de la tarjeta.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorComparativaAlias(){
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Debes introducir el alias de la tarjeta.',
      buttons: ['OK']
    });
    await alert.present();
  }

  //VALIDA NIF

  validateDNI(dni) {
    let numero, le, letra;
    let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      le = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != le) {
        //alert('Dni erroneo, la letra del NIF no se corresponde');
        return false;
      } else {
        //alert('Dni correcto');
        return true;
      }
    } else {
      //alert('Dni erroneo, formato no válido');
      return false;
    }
  }

  changeStep(value) {


    if (this.usuario.Nombre == undefined || this.usuario.Nombre == "") {
      this.alertErrorNombre();

    } else if (this.usuario.Apellidos == undefined || this.usuario.Apellidos == "") {
      this.alertErrorApellidos();

    } else if (!this.validateDNI(this.usuario.NIF)) {
      this.alertErrorNIF();

    } else if (this.usuario.Movil.toString().length !== 9) {
      this.alertErrorMovil();

    } else if (this.usuario.Email.localeCompare(this.usuario.ConfEmail) != 0) {
      this.alertEmail();

    } else if (this.usuario.Email.includes("@") == false || this.usuario.Email.includes(".") == false) {
      this.alertEmailNoValido();

    } else if (this.step2 == true) {
      this.step2 = value;
    }
    else if (this.usuario.Nombre && this.usuario.Apellidos && this.usuario.Movil && this.usuario.NIF && this.usuario.Email) {
      this.step2 = value;
    }
    else {
      this.presentAlert();
    }



  }



  postUser() {

    let myCode = Md5.hashStr(this.usuario.Password);

    let x = '{"clave" : "REGISTRO", "name" : "' + this.usuario.Nombre + '", "surname" : "' + this.usuario.Apellidos + '", "email" : "' + this.usuario.Email + '", "telefono" : "' + this.usuario.Movil + '", "nif" : "' + this.usuario.NIF + '", "password" : "' + myCode + '" }';


    this._consulta.registro(x).subscribe(
      (data) => {

        if (data['acceso'] == "No" && data['id'] == null) {
          this.alertEmailNoValido();
        } else {
          this._consulta.usuario.Id = data['id'];
          this.alertExitoRegistro();
          this._router.navigate(['/principal']);
        }

        if (this.usuario.TLazo != null && this.usuario.TLazo > 200000) {
          this.extraerDigitosLazo();
        }

        // console.log("Te has registrado " + this._consulta.usuario.Id)
        // this._consulta.usuario.Nombre = data['nombre'];
      },
      error => {
        console.log(<any>error);
        this.alertErrorRegistro();
      }
    )
  }

  extraerDigitosLazo() {
    let x = this.usuario.TLazo.toString();

    let longitud = x.length;

    console.log(longitud);

    if (x.length != 10) {
      this.alertErrorRegistroTarjetaLazo();
    } else {
      this.CTLazo = "CT" + x.substr(-6);
      this.registrarLazo();
    }
  }

  registrarLazo() {

    let clave = "LAZO";

    let payload = '{"clave" : "' + clave + '", "id" : "' + this._consulta.usuario.Id + '", "lazo" : "' + this.CTLazo + '", "descripcion" : "' + this.usuario.TLazoAlias + '" }';;

    this._consulta.sendLazo(payload).subscribe(
      (data) => {

        let x = (data)['acceso'];
        if (x == "OK") {
          console.log("Registro tarjeta Okey")
          this._router.navigate(['/principal']);
        }

        // console.log("Te has registrado " + this._consulta.usuario.Id)
        // this._consulta.usuario.Nombre = data['nombre'];
      },
      error => {
        console.log(<any>error);
        this.alertErrorRegistroTarjetaLazo();
      }
    )


  }

  mostrarCamara() {
    const options: CameraOptions = {
      quality: 70,
      destinationType: this._camera.DestinationType.FILE_URI,
      encodingType: this._camera.EncodingType.JPEG,
      mediaType: this._camera.MediaType.PICTURE
    }

    this._camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = 'data:image/jpeg;base64,' + imageData;

    }, (err) => {
      // Handle error
      console.log("Error en camara", JSON.stringify(err));
    });
  }

  submitBasic() {

    if ((this.usuario.TLazo == undefined || this.usuario.TLazo < 1) && (this.usuario.TLazoAlias != undefined)){
      this.alertErrorComparativaTLazo();
      this.usuario.TLazo == undefined;
      this.usuario.TLazoAlias = undefined;

    } else if((this.usuario.TLazoAlias == undefined) && (this.usuario.TLazo != undefined || this.usuario.TLazo > 1)){
      this.alertErrorComparativaAlias();
      this.usuario.TLazoAlias == undefined;

    }else  if (this.usuario.Password) {
      if (this.usuario.legalCondiciones == false) {
        this.alertTerminosYCondiciones();
      } else if (this.usuario.Password.localeCompare(this.usuario.ConfPass) != 0) {
        this.alertPass();
      } else {
        this.postUser();
      }
    }

    else {
      this.presentAlert();
    }

    //this.postUser();

  }

  // validateCheckboxLT(value) {
  //   this.usuario.legalTerminos = value;
  //   console.log(this.usuario.legalTerminos);
  // }

  validateCheckboxLC(value) {
    this.usuario.legalCondiciones = value;
    console.log(this.usuario.legalCondiciones);
  }

  terminos(value) {
    this._consulta.legal = value;
    console.log(value);
    this._router.navigate(['/aviso-legal']);
  }

}
