export class TarjetasLazo{
    public id : string;
    public identificadorapertura: string;
    public descripcion : string;

    constructor(){

        this.id = "";
        this.identificadorapertura = "";
        this.descripcion = "";
        
    }
}