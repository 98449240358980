import { Component, OnInit } from '@angular/core';
import {ConsultaService} from '../../services/consulta.service';

@Component({
  selector: 'app-retirar',
  templateUrl: './retirar.page.html',
  styleUrls: ['./retirar.page.scss'],
})
export class RetirarPage implements OnInit {

  constructor(
      public _consulta: ConsultaService,
  ) { }

  ngOnInit() {
  }

}
