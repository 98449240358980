export var Config = {
     url : 'http://bizia.es:8077/service/1/park',
     reg : 'http://bizia.es:8077/service/1/getAuth',
     sendData: 'http://bizia.es:8077/service/1/sendData'
 };

/*export var Config = {
    url : 'http://srv.pariver.com:8077/service/1/park',
    reg : 'http://srv.pariver.com:8077/service/1/getAuth',
    sendData: 'http://srv.pariver.com:8077/service/1/sendData'
};*/

export var Data = {
    email : '0',
    code : '0'
};
