import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { map, take } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { Usuarios } from '../models/usuarios';
import { Config } from './config';
import { Data } from './config';
import { TarjetasLazo } from '../models/tarjetasLazo';
import { Storage } from '@ionic/storage';
import { Login } from '../models/login';
import { Router } from '@angular/router';
import { Reservas } from '../models/reservas';

// import { AppPreferences } from '@ionic-native/app-preferences/ngx';


const STORAGE_KEY = 'myStorage';

@Injectable()
export class ConsultaService {
    public url: string;
    public reg: string;
    public sendData: string;
    public myLogin : Login;

    public misReservas: Reservas[]; //*n


    ajustes = {
        email : "",
        code : ""
    }

    public totalPlazas: string;

    public usuario: Usuarios;
    public tarjetaLazo: TarjetasLazo;

    public legal: boolean;
    // public id : string;

    public _email: string;
    public _code: string;

    constructor(
        public _http: HttpClient,
        public _http2: Http,
        public _storage: Storage,
        public _router: Router,
        // public _appPreferences : AppPreferences
    ) {
        this.url = Config.url;
        this.reg = Config.reg;
        this.sendData = Config.sendData;
        this._email = Data.email;
        this._code = Data.code;

        this.usuario = new Usuarios();
        this.tarjetaLazo = null;
        this.legal = false;
        this.misReservas=[];
        // this.usuario.Id = "44";
        // this.compruebaLogin();
    }

    
   

    compruebaLogin() {
        let email: string = "";
        let code: string = "";
        // console.log(this.getItems());

        // this._storage.get(this._email).then((e) => {
        //     email = e;
        //     console.log(e)
        // }).catch((error) => {
        //     console.log(error);
        // })

        // this._storage.get('code').then((c) => {
        //     code = c;
        //     console.log(c)
        // }).catch((error) => {
        //     console.log(error);
        // })

        // this._appPreferences.fetch('email').then((res)=>{
        //     email = res;
        // })

        // this._appPreferences.fetch('code').then((res)=>{
        //     code = res;
        // })


        if (email && code) {
            this.myLogin = new Login();
            this.myLogin.email = email;
            this.myLogin.password = code;

            this.postPlaza(this.myLogin).subscribe(
                (data) => {
                    this.usuario.Id = data['id'];
                    this.usuario.Nombre = data['nombre'];
                    this.usuario.Apellidos = data['apellidos'];

                    // this._appPreferences.store('email',  this.myLogin.email).then((res)=>{
                    //     console.log("Email: " + res);
                    // });
                    // this._appPreferences.store('code',  this.myLogin.password).then((res)=>{
                    //     console.log("Code: " + res);
                    // });

                    if (this.usuario.Id == null) {
                        // this.alertLoginNoValido();
                    } else {
                        //this._router.navigate(['/principal']);
                    }
                },
                error => {

                }
            )

        } else {
            //this._router.navigate(['/acceder']);

        }

        //let payload = '{"clave" : "LOGIN", "email" : "' + this.login.email + '","code" : "' + myCode + '"}';
    }

    postPlaza(payload: any) {
        // let req = <JSON>payload;
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.url, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    registro(payload: any) {
        // let req = <JSON>payload;
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.reg, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    envioDatos(payload:any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }


    miCuenta(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.sendData, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    sendLazo(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.url, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    getTarjetasLazo(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    sendPass(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.url, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    cerrarSesion() {
        this.usuario = new Usuarios();
    }

    sendLazoReserva(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.url, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    consultoMisReservas(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }
  

    deleteTarjetaLazo(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    deleteReserva(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    recPass(payload: any) {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http.post(this.url, payload, {
            headers: headers,
            responseType: 'json'
        })
    }

    //Esta petición consulta si hay alguna plaza libre, si la hay, envía la primera que encuentre.
    // postPlaza(payload : any) {
    //     return this._http.post(this.url, payload).pipe(map(res => res.json()));
    // }

    // postPlaza(clave){
    //     let payload = new FormData();
    //     payload.append('"clave"',clave );
    //     console.log(payload);
    //     return this._http.post(this.url,'{"clave": "CODIGO"}',{
    //         responseType : "json"
    //     })

    // }



}