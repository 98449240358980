import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActionSheetController, AlertController} from '@ionic/angular';
import {ConsultaService} from '../../services/consulta.service';
import {Map, latLng, tileLayer, Layer, marker, icon, popup} from 'leaflet';
import {Router} from '@angular/router';

@Component({
    selector: 'app-principal',
    templateUrl: './principal.page.html',
    styleUrls: ['./principal.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrincipalPage implements OnInit {

  public inicio : boolean;
  public map: Map;
  public marker: marker;


    constructor(
        public _consulta: ConsultaService,
        public alertController: AlertController,
        public _router: Router,
        private actionSheet: ActionSheetController
    ) {
        this.inicio = false;
    }

    ngOnInit() {
        // this.postLibres('"LIBRES"');
        this.postLibres();
        // this.loadmap();


    }
    ionViewWillEnter(){
        this.postLibres();
    }

    changeInicio(value) {
        this.inicio = value;
    }

    postLibres() {
        // let libres = '{"clave" : ' + lib + '}';
        let libres = JSON.stringify({'clave': 'LIBRES', 'id_instalacion': '1'});
        this._consulta.postPlaza(libres).subscribe(
            result => {
                this._consulta.totalPlazas = result['Total'];
                this.loadmap();
                // this.descripcion = data['descripcion'];
            },
            error => {
                console.log(<any>error);
                console.log('No ha sido posible consultar las plazas');
            }
        );
    }

    loadmap() {

        let biciicon = icon({
            iconUrl: '../../../assets/imagenes/aparcamientos_disponibles.png',
            shadowUrl: '',

            iconSize: [30, 40], // size of the icon

            iconAnchor: [10, 10], // point of the icon which will correspond to marker's location

            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        let bicidisp = icon({
            iconUrl: '../../assets/imagenes/aparcamientos_proximos.png',
            shadowUrl: '',

            iconSize: [30, 40], // size of the icon

            iconAnchor: [10, 10], // point of the icon which will correspond to marker's location

            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        setTimeout(() => {
            let that = this;
            this.map = new Map('map').setView([41.624769, -0.936810], 8);
            let marker1 = marker([41.656746, -0.908421], {icon: bicidisp});
            let popup1 = popup({
                    closeButton: false
                })
                    .setLatLng([41.656746,  -0.908421])
                    .setContent('Proximamente')

            ;
            /*marker1.addTo(this.map).on('click', function (e) {
                popup1.openOn(that.map);
            });*/
            let marker2 = marker([41.659393, -0.910928], {icon: bicidisp});
            let popup2 = popup({
                    closeButton: false
                })
                    .setLatLng([41.659393,-0.910928])
                    .setContent('Proximamente')

            ;
            /*marker2.addTo(this.map).on('click', function (e) {
                popup2.openOn(that.map);
            });*/
            let marker3 = marker([41.651654, -0.872895], {icon: bicidisp});
            let popup3 = popup({
                closeButton: false
            })
                .setLatLng([41.651654, -0.872895])
                .setContent('Proximamente');

           /*marker3.addTo(this.map).on('click', function (e) {
                popup3.openOn(that.map);
            });*/

            let bicisur = marker([41.624769, -0.936810], {icon: biciicon});
            bicisur.addTo(this.map).on('click', function (e) {
                that.presentAlert();
            });
            let marker4 = marker([41.652191, -0.880535], {icon: bicidisp});
            let popup4 = popup({
                    closeButton: false
                })
                    .setLatLng([41.652191, -0.880535])
                    .setContent('Proximamente')
            ;
            /*marker4.addTo(this.map).on('click', function (e) {
                popup4.openOn(that.map);
            });*/

            tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                // tslint:disable-next-line
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                minZoom: 15,
                maxZoom: 18
            }).addTo(this.map);

        }, 500);

    }
    async presentAlert() {
        const alert = await this.alertController.create({
            header: 'BICISUR',
            message: this._consulta.totalPlazas + ' plazas',
            buttons: [{
                text: 'Reservar',
                role: 'ok',
                handler: () => {
                    if(this._consulta.usuario.Id != null){
                        this._router.navigate(['/acceso']);
                    }else {
                        this._router.navigate(['/acceder']);
                    }
                }
            }]
        });
        await alert.present();


    }

    goAyuda() {
        this._router.navigate(['/ayuda']);
    }

}
