import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ConsultaService } from '../../services/consulta.service';
import { Usuarios } from 'src/app/models/usuarios';
import { Router, NavigationEnd } from '@angular/router';
import { TarjetasLazo } from 'src/app/models/tarjetasLazo';
import { NavController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-tarjeta-lazo',
  templateUrl: './tarjeta-lazo.page.html',
  styleUrls: ['./tarjeta-lazo.page.scss'],
})
export class TarjetaLazoPage implements OnInit {

  public usuario: Usuarios;
  public tarjetasLazo: TarjetasLazo[];
  public TLazo: string;
  public lazo: boolean;

  public miArray: TarjetasLazo[];

  constructor(
    // public navCtrl: NavController,
    private _consulta: ConsultaService,
    private _router: Router,
    public alertController: AlertController
  ) {
    // this.lazo = false;
    this.tarjetasLazo = [];
    this.usuario = new Usuarios();

  }

  ngOnInit() {
    this.getTarjetasLazo();
  }

  ionViewWillEnter() {
    this.getTarjetasLazo();
  }


  async alertEliminar(value) {
    let alert = await this.alertController.create({
      header: 'Confirmar eliminación de tarjeta',
      message: '¿Quiere eliminar esta tarjeta?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Cancelar');
          }
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.eliminarTLazo(value);
            console.log('Eliminar');
          }
        }
      ]
    });
    await alert.present();
  }


  getTarjetasLazo() {


    this.usuario = new Usuarios();

    let clave = "LISTATARJETA";

    let payload = '{"clave" : "' + clave + '", "id" : "' + this._consulta.usuario.Id + '" }';

    this._consulta.getTarjetasLazo(payload).subscribe(
      result => {
        // this.lazo = true;
        this.tarjetasLazo = result.value;

        let numerosLazo;

        // for(let i = 0; i < this.tarjetasLazo.length; i++){
        //   numerosLazo = this.tarjetasLazo[i].identificadorapertura.substr(-4);
        //   this.tarjetasLazo[i].identificadorapertura = numerosLazo;
        // }

        if (this.tarjetasLazo.length != 0) {
          this.lazo = true;
        } else {
          this.lazo = false;
        }


      },
      error => {

      }
    )


  }

  eliminarTLazo(value) {

    let x = '{"clave" : "DELETELAZO", "lazo" : "' + value + '" }';
    this._consulta.deleteTarjetaLazo(x).subscribe(
      result => {
        let y = result['result'];
        console.log(y);
        if (y == "OK") {
          console.log("TARJETA BORRADA");
        } else {
          console.log("ERROR");
        }
        this.getTarjetasLazo();
      },
      error => {
        console.log("No se ha podido eliminar la tarjeta.")
      }
    )

    // this.presentAlert("Alerta!", "¿Quiere eliminar esta tarjeta?");
    
  }


  async presentAlert(head, str) {
    const alert = await this.alertController.create({
      header: head,
      message: str,
      buttons: ['OK']
    });
    await alert.present();
  }


}
