import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConsultaService } from 'src/app/services/consulta.service';
import {Md5} from 'ts-md5/dist/md5';
import { Login } from '../../models/login';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { Data } from '../../services/config';
import { Config } from '@ionic/core';

// import { AppPreferences } from '@ionic-native/app-preferences/ngx';


@Component({
  selector: 'app-acceder',
  templateUrl: './acceder.page.html',
  styleUrls: ['./acceder.page.scss'],
  providers:[]
})
export class AccederPage implements OnInit, OnDestroy {
  
  public login : Login;
  public _email : string;
  public _code : string;
  
  constructor(
    public _consulta : ConsultaService,
    public _router : Router,
    public alertController: AlertController,
    public _storage : Storage,
    // public _appPreferences : AppPreferences
    // private nativeStorage : NativeStorage
  ) { 
    this.login = new Login();
    this._email = Data.email;
    this._code = Data.code;
  }
  
  ngOnInit() {
    // // this.getMyStorage();
    // this._consulta.compruebaLogin();
    // let id = Number(this._consulta.usuario.Id);
    // console.log(id);
    // if(this._email != "0" ){
    //   this._router.navigate(['/principal']);
    // }else{
    // }
  }

  ngOnDestroy(){
    this.login = new Login();
  }

  async alertErrorLogin() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Algo ha ido mal... Inténtalo más tarde.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertLoginNoValido() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'El Email o la Contraseña no es válido.',
      buttons: ['OK']
    });
    await alert.present();
  }

  onSubmit(){

    let myCode = Md5.hashStr(this.login.password);
    
    let payload = '{"clave" : "LOGIN", "email" : "' + this.login.email + '","code" : "' + myCode + '"}';


    this._consulta.postPlaza(payload).subscribe(
      (data)=> {
        this._consulta.usuario.Id = data['id'];
        this._consulta.usuario.Nombre = data['nombre'];
        this._consulta.usuario.Apellidos = data['apellidos'];

        // this._appPreferences.store('email',  this._consulta.myLogin.email).then((res)=>{
        //   console.log("Email: " + res);
        // });
        // this._appPreferences.store('code',  this._consulta.myLogin.password).then((res)=>{
        //     console.log("Code: " + res);
        // });

        if(this._consulta.usuario.Id == null){
          this.alertLoginNoValido();
        }else{
          this._router.navigate(['/principal']);
        }
      },
      
      error => {
        this.alertErrorLogin();
        console.log(<any>error);
      }
    )
  }

  // setMyStorage(){
  //   this.nativeStorage.setItem('Id',this._consulta.usuario.Id).then(
  //     (data)=> {
  //       console.log("Almacenado!");
  //     },
  //     error=>{
  //       console.log("Error de Almacenamiento", error);
  //     }
  //   )
  // }

  // getMyStorage(){
  //   this.nativeStorage.getItem('Id').then(
  //     data => {
  //       this._consulta.usuario.Id = data;
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   )
  // }

}
