import { Component, OnInit } from '@angular/core';


import { ConsultaService } from '../../services/consulta.service';

@Component({
  selector: 'app-aparcar',
  templateUrl: './aparcar.page.html',
  styleUrls: ['./aparcar.page.scss'],
  providers: [ConsultaService]
})
export class AparcarPage implements OnInit {

  // public output : JSON;

  // Datos que delvuelve postConsultaPlazaLibre()
  public NumPlaza : string;
  public accessCode : string;
  public acceso : string;

  // public Total : string;
  // public estoyLogueado : boolean;

  constructor(
    public _consulta : ConsultaService
  ) { 
    
  }

  ngOnInit() {

  }


  postConsultaPlazaLibre(cod : string){
    let codigo = '{"clave" : ' + cod + '}';

    this._consulta.postPlaza(codigo).subscribe(
      (data)=> {
        this.NumPlaza = data['plaza'];
        this.accessCode = data['codigo'];
      },
      error => {
        console.log(<any>error);
        console.log("No ha sido posible consultar las plazas")
      }
    )
  }

  postLibres(lib : string){
    let libres = '{"clave" : ' + lib + '}';
    this._consulta.postPlaza(libres).subscribe(
      (data)=> {
        this._consulta.totalPlazas = data['Total'];
        // this.descripcion = data['descripcion'];
      },
      error => {
        console.log(<any>error);
        console.log("No ha sido posible consultar las plazas")
      }
    )
  }

  
}
