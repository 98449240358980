import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Usuarios } from 'src/app/models/usuarios';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';


@Component({
  selector: 'app-metodos-pago',
  templateUrl: './metodos-pago.page.html',
  styleUrls: ['./metodos-pago.page.scss'],
})
export class MetodosPagoPage implements OnInit {

  public credito :boolean;
  public lazo : boolean;


  constructor(
    public _router: Router,
    public _consulta: ConsultaService,
  ) { 

    this.credito = false;
    this.lazo = false;
  }

  ngOnInit() {
  }

}
