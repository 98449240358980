import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import { PrincipalPage } from '../app/pages/principal/principal.page';
// import { AparcarPage } from './pages/index.pages';

import { PrincipalPageModule } from './pages/principal/principal.module';
import { AparcarPageModule } from './pages/aparcar/aparcar.module';
import { AccesoPageModule } from './pages/acceso/acceso.module';
import { AccederPageModule } from './pages/acceder/acceder.module';
import { RetirarPageModule } from './pages/retirar/retirar.module';
import { ConsultaService } from './services/consulta.service';
import { RegistrarsePageModule } from './pages/registrarse/registrarse.module';
import { MetodosPagoPageModule } from './pages/metodos-pago/metodos-pago.module';
import { TarjetaCreditoPageModule } from './pages/tarjeta-credito/tarjeta-credito.module';
import { TarjetaLazoPageModule } from './pages/tarjeta-lazo/tarjeta-lazo.module';
import { ChangePassPageModule } from './pages/modals/change-pass/change-pass.module';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NavController } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage';
import { ElegirTarjetaLazoPageModule } from './pages/elegir-tarjeta-lazo/elegir-tarjeta-lazo.module';
// import { AppPreferences } from '@ionic-native/app-preferences/ngx';

//Plugins

// import { Camera } from '@ionic-native/camera';

@NgModule({
  declarations: [
    AppComponent, 
    // AppPreferences
    // AparcarPage,
    // PrincipalPage
  ],
  entryComponents: [
    // AparcarPage,
    // PrincipalPage
  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(), 
    // AppPreferences,
    AppRoutingModule,
    AparcarPageModule,
    PrincipalPageModule,
    AccesoPageModule,
    AccederPageModule,
    RetirarPageModule,
    RegistrarsePageModule,
    MetodosPagoPageModule,
    TarjetaCreditoPageModule,
    TarjetaLazoPageModule,
    ChangePassPageModule,
    ElegirTarjetaLazoPageModule
  ],
  providers: [
    // NativeStorage,
    StatusBar,
    SplashScreen,
    ConsultaService,
    NavController,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
