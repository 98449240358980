import { MisReservasPage } from './../mis-reservas/mis-reservas.page';
import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Router } from '@angular/router';
import { TarjetaLazoPage } from '../tarjeta-lazo/tarjeta-lazo.page';
import { AlertController, IonDatetime } from '@ionic/angular';
import { Reservas } from 'src/app/models/reservas';

@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.page.html',
  styleUrls: ['./acceso.page.scss'],
})
export class AccesoPage implements OnInit {

  public codigo : string;
  public tarjetasLazo : TarjetaLazoPage;
  public codigoActivo: boolean;
  
  constructor(
    public _consulta : ConsultaService,
    private _router : Router,
    private alertController : AlertController

  ) {
    this.tarjetasLazo = new TarjetaLazoPage(_consulta, _router, alertController);
    this.codigoActivo = true;
   }

  ngOnInit() {
    this.tarjetasLazo.getTarjetasLazo();
    this.getResevas();
  }

  async alertErrorSeleccionarTarjetasLazo() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'No tienes Tarjetas Lazo registradas.',
      buttons: ['OK']
    });
    await alert.present();
  }


  volver(){
    this._router.navigate(['/principal']);
  }
  
  elegirTLazo(){
    if(this.tarjetasLazo.tarjetasLazo.length >= 1){
      this._router.navigate(['/elegir-tarjeta-lazo']);    
    }else{
      this.alertErrorSeleccionarTarjetasLazo();
    }
  }

  elegirCodigo(){
    if(this.codigoActivo){
    this._router.navigate(['/elegir-codigo']);    
    }
  }

  getResevas(){
    let x = '{"clave" : "RESERVAS", "id" : "' + this._consulta.usuario.Id + '" }';
    this._consulta.consultoMisReservas(x).subscribe(
      result => {
        this._consulta.misReservas = result.value;
        this.comprobarReservaCodigo();
      },
      error => {
        console.log(<any>error);
      }
    )
  }

  comprobarReservaCodigo(){  
    console.log(this._consulta.misReservas);
    this._consulta.misReservas.forEach(reserva => {
      if((reserva.descripcion == "code" && reserva.estado=="B") || (reserva.descripcion == "code" && reserva.estado=="R")){
          document.getElementById('reservaCodigo').style.opacity="0.3";
          document.getElementById('reservaCodigoTxt').style.opacity="0.3";
          document.getElementById('codigoEnUso').innerHTML = "<br />Tienes un código en uso, consúltalo en Mis Reservas";
          this.codigoActivo = false;
        }
    });
  }

}
